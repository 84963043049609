import React, { useEffect } from 'react';

import {
  Box
}from '@mui/material';

import CommunityTabs from './tabs';
import { useLocation } from 'react-router-dom';
import queryString from 'query-string';
import { CommunityFilterProvider } from '@components/v2/organisms';

export default function Community() {
  const [activeTab, setActiveTab] = React.useState(0);
  const { search } = useLocation();
  const values = queryString.parse(search);

  useEffect(() => {
    if(values.activeTab){
      if(values.activeTab == 'courses'){
        setActiveTab(2);
      }
      if(values.activeTab == 'forums'){
        setActiveTab(1);
      }
      if(values.activeTab == 'checkins'){
        setActiveTab(0);
      }
    }
    if(values.topicId){
      setActiveTab(1);
    }
  }, [values]);

  return (
    <CommunityFilterProvider isSearchEnabled={true}>
      <Box sx={{ height: '100%', width: '100%', bgcolor: 'background.paper' }}>
        <CommunityTabs activeTab={activeTab} setActiveTab={setActiveTab}/>
      </Box>
    </CommunityFilterProvider>
  );
  
}