import React, { useContext, useState } from 'react';
import { Share } from '@capacitor/share';
import { Link, useHistory } from 'react-router-dom';
import { 
  Box,
  Divider,
  IconButton,
  Stack,
  Typography
} from '@mui/material';

import DeleteIcon from '@mui/icons-material/Delete';
import ShareIcon from '@mui/icons-material/Share';
import PushPinIcon from '@mui/icons-material/PushPin';

import { grey } from '@mui/material/colors';

import { UserDetails } from '@components/molecules';
import { LoadableText, Image } from '@components/atoms';
import { useTheme } from '@emotion/react';
import { UserContext, EventsContext } from '@context';
import { LikeAndCommentButtons, ConfirmationModal } from '@components/molecules';
import  { PostFeedContext } from './post-feed-context';
import PostPrivacy from './post-privacy';
import { NewPostContext } from '../new-post/new-post-context';

import config from '@config';

export default function Post(props) {
  const { user, isMobileApp } = useContext(UserContext);
  const { trackEvent } = useContext(EventsContext);
  const { post, isLoading } = props;
  const { openNewPostDialog } = useContext(NewPostContext);
  const { deletePost, likePost, saveComment, setPostBeingViewed } = useContext(PostFeedContext);
  const [isDeleteConfirmationModalOpen, setIsDeleteConfirmationModalOpen] = useState(false);
  var theme = useTheme();
  var history = useHistory();

  const openCreateComment = () => {
    trackEvent('Community:Posts:Comment:Create:Open', { postId: post.id });
    openNewPostDialog({ 
      title: 'New Check-In', 
      placeholder: 'Your comment...', 
      buttonAction: ({ message }) => {saveComment({ postId: post.id, message });}, 
      successToastMessage : 'Comment Posted!',
      isImageEnabled: false
    });
  };

  const share = async () => {
    trackEvent('Community:Share:Open');
    var options = {
      text: post.message,
      subject: null, // fi. for emailan array of filenames either locally or remotely
      url: null,
      dialogTitle: 'Brace' // Android only, you can override the default share sheet title
    };
  
    await Share.share(options);
  };

  var linkTo = `/community?checkInId=${post?.id}`;

  if(post?.metadata){
    if(post?.metadata.type == 'NEW_TOPIC'){
      linkTo = `/community?topicId=${post?.metadata.topicId}`;
    }
    
  }

  var border = post?.pinned ?
    theme.palette.mode == 'light' ? 
      `2px solid ${grey[900]}` :
      `2px solid ${grey[100]}`  :
    'none';

  var padding = post?.pinned ? '2px' : 0;

  const handleClick = () => {
    if(post?.metadata){
      if(post?.metadata.type == 'NEW_TOPIC'){
        history.push(`/community?topicId=${post?.metadata.topicId}`);
        return;
      }
    }

    setPostBeingViewed(post);
  };

  return (
    <Box sx={{ padding: padding }}>
      <Box sx={{ backgroundColor: theme.palette.background.paper, marginBottom: '1px', border: border, borderRadius: 1 }}>
        <Divider light/>
        <Box sx={{ px: 1 }}>
         
          <Box sx={{ display: 'flex', py:1 }}>
            <Box sx={{ flexGrow: 1 }}>
              <UserDetails user={post?.user} date={post?.createdAt} isLoading={isLoading}/>
            </Box>
            {post?.pinned &&
              <Box sx={{ display: 'flex', color: 'text.disabled', justifyContent: 'center' }}>
                <PushPinIcon color='disabled' fontSize='small' />
                <Typography sx={{ marginLeft: 0.5 }} variant='body2'>
                  Pinned
                </Typography>
              </Box>
            }
          </Box>
          <Box sx={{ py: 0.5 }}>
            <Box sx={{ typography: 'body1', color: 'text.primary' }} onClick={handleClick}>
              <LoadableText text={post?.message}
                isLoading={isLoading}
                minWidth={100}
                dataTestId={`post-${post?.id}-message`}/>
            </Box>
          </Box>
          {post?.imageUrl &&
          <Box sx={{ paddingBottom: 0.5, px: 1 }}>
            <Box onClick={handleClick}>
              <Image src={post?.imageUrl}
                sx={{ 
                  [theme.breakpoints.down('md')]: {
                    maxWidth: '80%'
                  },
                  [theme.breakpoints.only('md')]: {
                    maxWidth: '50%'
                  },
                  [theme.breakpoints.up('lg')]: {
                    maxWidth: '33%'
                  }   
                  
                }} />
            </Box>
          </Box>
          }
          <Box sx={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between' }}>
            <PostPrivacy isLoading={isLoading} post={post}/>
            <Stack spacing={1} direction="row">
              {(user?.id && user?.id == post?.user.id) && 
             <Stack spacing={1} direction="row" sx={{ marginRight: 1 }}>
               <IconButton sx={{ p: 0, color: 'text.disabled' }} onClick={() => setIsDeleteConfirmationModalOpen(true)}>
                 <DeleteIcon sx={{ fontSize: '1rem' }} />
               </IconButton>
               {isMobileApp &&
              <IconButton sx={{ p: 0, color: 'text.disabled' }} onClick={() => share()}>
                <ShareIcon sx={{ fontSize: '1rem' }} />
              </IconButton>
               }
             </Stack>
              }
              {post?.metadata?.type == 'NEW_TOPIC' && 
              <Box sx={{ typography: 'body1', color: 'text.primary', textDecoration: 'none' }} component={Link} to={linkTo}>
                <Typography sx={{ marginLeft: 0.5, color: 'rgb(144, 202, 249)' }} variant='body1'>
                  Go To Topic
                </Typography>
              </Box>
              }
              {post?.metadata?.type != 'NEW_TOPIC' && 
               <LikeAndCommentButtons 
                 isLoading={isLoading}
                 data={{ likeCount:post?.likeCount, commentCount:post?.commentCount, likedByCurrentUser: post?.likedByCurrentUser }}
                 likeAction = {() => likePost(post.id)}
                 commentAction={openCreateComment}
               />
              }
             
            </Stack>
         
          </Box>
        </Box>
        <ConfirmationModal isOpen={isDeleteConfirmationModalOpen} 
          handleClose={() => setIsDeleteConfirmationModalOpen(false)}
          title='Are You Sure?'
          message='If you delete this post it will no longer appear for other users.'
          confirmationSuccessMessage = 'Post deleted.'
          handleConfirmation={async () => {
            await deletePost(post.id);
          }}
        />
    
        <Divider light/>
      </Box>
    </Box>
  );
}