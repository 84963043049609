import React from 'react';

import {
  Box,
  Tabs,
  Tab,
  AppBar
}from '@mui/material';
import { useTheme } from '@mui/material/styles';
import { styled } from '@mui/material/styles';

import { grey } from '@mui/material/colors';

import Posts from './posts';
import Topics from './topics';

const CustomTabs = styled(Tabs)(({ theme }) => ({
  boxShadow: 'none',
  minHeight: '40px',
  '& button':{
    padding:'0px',
    minHeight: '40px'

  },
  '& .MuiTabs-root':{
    minHeight: '40px'
  },
  '& .MuiTabs-indicator':{
    width:'33%!important',
    height: '0px'
  },
  '& .Mui-selected':{
    color: '#fff',
    backgroundColor:theme.palette.braceGreenDark.main
  }
}));

const CustomAppBar = styled(AppBar)({
  boxShadow: 'none'
});

export default function CommunityTabs(props) {
  const theme = useTheme();
  const { activeTab, setActiveTab } = props;

  const handleChange = (event, newValue) => {
    setActiveTab(newValue);
  };

  function TabPanel(props) {
    const { children, value, index, ...other } = props;
  
    return (
      <div
        role="tabpanel"
        style={{ flexGrow: 1, overflow: 'hidden' }}
        hidden={value !== index}
        id={`full-width-tabpanel-${index}`}
        aria-labelledby={`full-width-tab-${index}`}
        {...other}
      >
        {value === index && (
          <Box sx={{ height: '100%', background: theme.palette.mode === 'dark' ? grey[900] : '#fafafa'  }}>
            {children}
          </Box>
        )}
      </div>
    );
  }

  function a11yProps(index) {
    return {
      id: `full-width-tab-${index}`,
      'aria-controls': `full-width-tabpanel-${index}`
    };
  }

  return (
    <Box sx={{ height: '100%', display: 'flex', flexDirection: 'column' }}>
      <CustomAppBar position="static"
        color="transparent"
        sx={{
          backgroundColor: theme.backgroundColor
        }}>
        <CustomTabs
          value={activeTab}
          onChange={handleChange}
          indicatorColor="secondary"
          textColor="inherit"
          variant="fullWidth"
          aria-label="full width tabs example"
        >
          <Tab data-testid='community-tabs-check-ins' label="Check-Ins" {...a11yProps(0)} />
          <Tab data-testid='community-tabs-forums' label="Discussions" {...a11yProps(1)} />
        </CustomTabs>
      </CustomAppBar>
      <TabPanel value={activeTab} index={0} dir={theme.direction}>
        <Posts/>
      </TabPanel>
      <TabPanel value={activeTab} index={1} dir={theme.direction}>
        <Topics />
      </TabPanel>

    </Box>
  );
  
}