import React from 'react';

import Crown from './crown';
import Chat from './chat';
import Home from './home';
import Learn from './learn';
import Group from './group';
import Dumbbell from './dumbbell';
import Diary from './diary';
import Bell from './bell';
import Trophy from './trophy';
import User from './user';
import Star from './star';
import TargetIcon from './target';
import { useTheme } from '@emotion/react';
import CheckCircle from './check-circle';
import Circle from './circle';
import { Skeleton } from '@mui/material';
import StretchingPerson from './stretching-person';
import CommentPlus from './comment-plus';
import ThumbUp from './thumb-up';
import Conversation from './conversation';
import SentimentContent from './sentiment-content';
import SentimentDelighted from './sentiment-delighted';
import SentimentTired from './sentiment-tired';
import SentimentOk from './sentiment-ok';
import SentimentAngry from './sentiment-angry';

const I = (props) => {
  const { type } = props;

  switch(type){
  case 'chat':
    return <Chat {...props}/>;
  case 'learn':
    return <Learn {...props}/>;
  case 'group':
    return <Group {...props}/>;
  case 'dumbbell':
    return <Dumbbell {...props}/>;
  case 'diary':
    return <Diary {...props}/>;
  case 'home':
    return <Home {...props}/>;
  case 'bell':
    return <Bell {...props}/>;
  case 'trophy':
    return <Trophy {...props}/>;
  case 'target':
    return <TargetIcon {...props}/>;
  case 'user':
    return <User {...props}/>;
  case 'star':
    return <Star {...props}/>;
  case 'check-circle':
    return <CheckCircle {...props}/>;
  case 'circle':
    return <Circle {...props}/>;
  case 'stretching-person':
    return <StretchingPerson {...props}/>;
  case 'comment-plus':
    return <CommentPlus {...props}/>;
  case 'thumb-up':
    return <ThumbUp {...props}/>;
  case 'conversation':
    return <Conversation {...props}/>;
  case 'sentiment-content':
    return <SentimentContent {...props}/>;
  case 'sentiment-delighted':
    return <SentimentDelighted {...props}/>;
  case 'sentiment-tired':
    return <SentimentTired {...props}/>;
  case 'sentiment-ok':
    return <SentimentOk {...props}/>;
  case 'sentiment-angry':
    return <SentimentAngry {...props}/>;
    
  case 'crown':
  default:
    return <Crown {...props}/>;
  }
};

export default function Icon(props){
  const theme = useTheme();
  var { isLoading, type, stroke=theme.palette.primary.main, fill=theme.palette.primary.main, size=32 } = props;

  if(isLoading){
    return <Skeleton variant="rounded" style={{ width:size, height:size }} />;
  }

  return (
    <I 
      style={{ minWidth: `${size}px` }}
      type={type}
      fill={fill}
      stroke={stroke}
      size={size} />
  );
}