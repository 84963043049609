import React from 'react';

import { 
  Button as MuiButton
} from '@mui/material';

export default function Button(props){
  return (
    <MuiButton 
      {...props}
      sx={{
        ...props.sx,
        fontWeight: 'medium',
        lineHeight: 1
      }}
    /> 
  );
}