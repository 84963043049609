import React, { useEffect, useState } from 'react';
import { useAuth0 } from '@auth0/auth0-react';
import { useParams } from 'react-router-dom';
import { 
} from '@mui/material';
import { ThemeProvider } from '@mui/material/styles';
import { Browser } from '@capacitor/browser';
import { App as CapApp } from '@capacitor/app';

import { setCookie, COOKIE_NAMES } from './../../../helpers/cookies';

import config from '@config';
import { useContext } from 'react';
import { UserContext } from '@context';

import Onboarding from './onboarding';
import { LoadingPage } from '@components/molecules';
import { useTheme } from '@emotion/react';
import { forceLightTheme } from '@helpers/theme';
import { EventsContext } from '@context/events';

export default function Index(props) {
  var theme = useTheme();
  let { inviteToken } = useParams();
  const [hasShownLogin, setHasShownLogin] = useState(false);
  const { doAfterClientSignUp, doAfterPractitionerSignUp, defaultUserType } = props;
  const { loginWithRedirect, handleRedirectCallback } = useAuth0();
  const { user, isLoading, isAuthenticated, handleLogout } = useContext(UserContext);
  const { trackEvent } = useContext(EventsContext);

  useEffect(() => {
    trackEvent('Index:Open');
  }, []);

  useEffect(() => {
    CapApp.addListener('appUrlOpen', async ({ url }) => {
      if (url.includes('state') && (url.includes('code') || url.includes('error'))) {
        await handleRedirectCallback(url);
      }
      // No-op on Android
      await Browser.close();
    });
  }, [handleRedirectCallback]);

  const login = () => {
    if(inviteToken){
      setCookie({ name: COOKIE_NAMES.INVITE_TOKEN , value: inviteToken, minutes: 1 });
    }
    config.isMobileApp ?
      loginWithRedirect({
        async openUrl(url){
          await Browser.open({
            url,
            windowName: '_self'
          });
        }

      }) : 
      loginWithRedirect();
  };

  if(!isLoading && !isAuthenticated || !user?.id){
    if(!hasShownLogin){
      setHasShownLogin(true);
      trackEvent('Index:Authentication:Open');
      login();
    }
  }

  if(user?.status === 'PENDING'){
    return (
      <ThemeProvider theme={forceLightTheme(theme)}>
        <Onboarding
          doAfterClientSignUp={doAfterClientSignUp}
          doAfterPractitionerSignUp={doAfterPractitionerSignUp}
          defaultUserType={defaultUserType}/>
      </ThemeProvider>
    );
  }

  return(
    <ThemeProvider theme={forceLightTheme(theme)}>
      <LoadingPage onClick={handleLogout}/>
    </ThemeProvider>
  );
}