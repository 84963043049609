import React, { useContext } from 'react';

import { 
  Box, 
  Typography,
  FormControl,
  RadioGroup,
  FormControlLabel,
  Button
} from '@mui/material';
import SortIcon from '@mui/icons-material/Sort';

import { Dialog, Radio } from './../../atoms';
import { CommunityFilterContext } from './context';

export default function CommunityFiltersDialog(){
  var { isFiltersDialogOpen, setIsFiltersDialogOpen, sortBy, handleSortByChange } = useContext(CommunityFilterContext);

  return (
    <Dialog isOpen={isFiltersDialogOpen} close={() => setIsFiltersDialogOpen(false)}>
      <Box sx={{ p: 2 }}>
        <Box sx={{ display: 'flex' }}>
          <SortIcon color="disabled"/>
          <Typography variant='h5' sx={{ marginLeft: 1 }}>
          Sort By
          </Typography>
        </Box>
        <FormControl sx={{
          py: 2,
          '.MuiRadio-root': { paddingLeft: 1, py: 0.75 }
        }}>
          <RadioGroup
            value={sortBy}
            onChange={(event) => {
              handleSortByChange(event.target.value);
            }}
          >
            <FormControlLabel size="small"
              value="POPULAR"
              control={<Radio size="small" />}
              label="Popular" />
            <FormControlLabel size="small"
              value="LATEST"
              control={<Radio size="small" />}
              label="Most Recent" />
            <FormControlLabel size="small"
              value="ENGAGED"
              control={<Radio size="small" />}
              label="Most Engaged" />
          </RadioGroup>
        </FormControl>
        <Button 
          color= 'braceGreenDark'
          fullWidth 
          variant='contained'
          onClick={() => setIsFiltersDialogOpen(false)}>
          Show Result
        </Button>
      </Box>
    </Dialog>
    
  );
}