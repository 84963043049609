
import React from 'react';

export default function Chat(){
  return (
    <svg width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg">
      <path d="M4.9 17.5C3.7 16 3 14.1 3 12C3 7 7 3 12 3C17 3 21 7 21 12C21 17 17 21 12 21C11.1 21 10.2 20.9 9.4 20.6C8.9 20.5 8.4 20.3 8 20"
        strokeWidth="1.5"
        strokeMiterlimit="10"
        strokeLinecap="round"
        strokeLinejoin="round"/>
      <path d="M3 21C3.3 21 3.6 21 3.9 21C5.4 21 6.7 20.7 7.9 20"
        strokeWidth="1.5"
        strokeMiterlimit="10"
        strokeLinecap="round"
        strokeLinejoin="round"/>
      <path d="M4.9 17.5C4.8 18.9 4 20.1 3 21"
        strokeWidth="1.5"
        strokeMiterlimit="10"
        strokeLinecap="round"
        strokeLinejoin="round"/>
      <path d="M12 9C12.5523 9 13 8.55228 13 8C13 7.44772 12.5523 7 12 7C11.4477 7 11 7.44772 11 8C11 8.55228 11.4477 9 12 9Z"
        strokeWidth="1.5"
        strokeMiterlimit="10"
        strokeLinecap="round"
        strokeLinejoin="round"/>
      <path d="M8.12598 15C8.57002 13.2748 10.1361 12 12 12C13.8638 12 15.4299 13.2748 15.8739 15"
        strokeWidth="1.5"
        strokeMiterlimit="10"
        strokeLinecap="round"
        strokeLinejoin="round"/>
    </svg>
  );
}