import React from 'react';

import { 
  IconButton as MuiIconButton
} from '@mui/material';

export default function IconButton(props){
  return (
    <MuiIconButton 
      {...props}
      sx={{
        ...props.sx,
        backgroundColor: 'rgba(255, 255, 255, 0.12)'
      }}
    /> 
  );
}