import React, { createContext, useContext, useEffect, useState  } from 'react';
import { UserContext } from './user';
import { ServicesContext } from './services';
import { useDispatch } from 'react-redux';
import { EventsContext } from './events';

export const AccountOnboardingContext = createContext();

export function AccountOnboardingProvider(props){
  const { openSuccessModal } = props;
  const [accountOnboarding, setAccountOnboarding] = useState(null);
  const [isLoadingAccountOnboarding, setIsLoadingAccountOnboarding] = useState(false);
  const { trackEvent } = useContext(EventsContext);
  var dispatch;

  const { user, getUserToken } = useContext(UserContext);
  const { api } = useContext(ServicesContext);

  useEffect(() => {
    if(user){
      loadAccountOnboarding();
    }
  }, [user]);

  if(openSuccessModal){
    dispatch = useDispatch();
  }

  const loadAccountOnboarding = async () => {
    var wasAccountOnboardingComplete = accountOnboarding?.isComplete;
    if(!accountOnboarding?.isComplete){
      setIsLoadingAccountOnboarding(true);
      var result = await api.get({ url: 'accounts/onboarding', getUserToken });
      setAccountOnboarding(result);
      setIsLoadingAccountOnboarding(false);
      
      if(wasAccountOnboardingComplete == false && result.isComplete && openSuccessModal){
        trackEvent('AccountOnboarding:Complete');
        dispatch(openSuccessModal(
          'Congratulations!',
          <>
            <p>You are a Brace Pro! We have added a badge to your avatar, check it out!</p>
            <p>Brace Pros get 10% off your first subscription, you can see and redeem your discount code on the subscriptions page!</p>
          </>,
          'star'
        ));
      }
    }
  };

  return (
    <AccountOnboardingContext.Provider value={{ 
      accountOnboarding,
      loadAccountOnboarding,
      isLoadingAccountOnboarding
    }}>
      {props.children}
    </AccountOnboardingContext.Provider>
  );
}