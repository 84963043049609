import React, { createContext, useContext, useEffect, useState  } from 'react';
import mixpanel from 'mixpanel-browser';
import { App } from '@capacitor/app';

export const EventsContext = createContext({});

import { UserContext } from './user';
import { ServicesContext } from './services';

import config from '@config';

export function EventsProvider(props){
  const { user, auth0User } = useContext(UserContext);
  const { logger } = useContext(ServicesContext);
  const [ appVersion, setAppVersion ] = useState(0);

  useEffect(() => {
    if(user?.id &&
      config.isMobileApp
    ){
      App.getInfo()
        .then((info) => {
          setAppVersion(info.version);
        });
    }
  }, [user]);

  const trackEvent = (name, properties = {}) => {
    var logString = `${config.isMobileApp ? 'Mobile' : 'Web'}:Event:${name}`;
    
    if(user && user?.id){
      properties.userId=user.id;
      properties.userName=user.name;
    }

    properties.jsVersion=config.version;
    if(appVersion){
      properties.appVersion=appVersion;
      
    }

    if(auth0User && auth0User?.sub){
      properties.authId=auth0User.sub;
    }

    properties.deviceType = config.isMobileApp ? 'Mobile' : 'Web';

    logger.info(logString, properties);
    mixpanel.track(name, {
      deviceType: config.isMobileApp ? 'Mobile' : 'Web',
      appVersion,
      jsVersion: config.version
    });
  };

  return (
    <EventsContext.Provider value={{
      trackEvent
    }}>
      {props.children}
    </EventsContext.Provider>
  );
}